<template>
    <div class="super-privacy-introduction">
        <i class="material-icons close-btn" @click="back">close</i>
        <div class="container">
            <img class="top-img" src="@/assets/images/super-privacy/super_privacy_01.png" />
            <!-- <div class="blank" /> -->
        </div>
        <div class="box-grad-privacy flex">
            <div class="btn-privacy free-privacy flex" @click="onClickFree" v-html="'무료로 사용하기'"></div>
            <img class="hr-or" src="@/assets/images/hr_or.png" />
            <div class="btn-privacy buy-privacy flex" @click="onClickBuy">
                <span class="title" v-html="'슈퍼 프라이버시 30일권'" />
                <span class="price" v-html="`4,800원 <span class='f-regular'>(VAT별도)`" />
            </div>
        </div>
    </div>
</template>

<script>
import productService from '@/services/product'

export default {
    name: 'SuperPrivacyIntroductionPage',
    props: {
        agent: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        privacyItem: null,
    }),
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            try {
                const { privacy } = await productService.all()
                this.privacyItem = privacy[0]
            } catch (e) {}
        },
        back() {
            this.$stackRouter.pop()
        },
        onClickFree() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'superPrivacyFreeEvent',
                    option: {
                        funnel: 'click_free_super_privacy_button',
                    },
                },
            })

            this.$stackRouter.push({
                name: 'InvitationFriendPage',
                props: {
                    // productType: 'premium',
                    // agent: this.agent,
                },
            })
        },
        onClickBuy() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'superPrivacyBuyEvent',
                    option: {
                        funnel: 'click_purchase_super_privacy_button',
                    },
                },
            })

            const appVersion = this.$store.getters.device.app_version
            if (appVersion < '1.2.0') {
                this.$toast.error('앱 업데이트 후 진행이 가능합니다. 앱을 업데이트 해주세요!')
                return
            }

            this.$store.dispatch('requestInAppPurchase', this.privacyItem)

            // this.$stackRouter.push({
            //     name: 'PgStorePage',
            //     props: {
            //         productType: 'premium',
            //         agent: this.agent,
            //     },
            // })
        },
    },
}
</script>

<style lang="scss" scoped>
.super-privacy-introduction {
    height: 100vh;
    overflow: auto;

    .close-btn {
        position: fixed;
        left: 16px;
        top: 16px;
        color: white;
        z-index: 1;
        width: 28px;
        height: 28px;
        padding: 2px;
        border-radius: 14px;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .hr-or {
        // height: 20px;
        width: calc(100vw - (24px * 2));
        margin: 10px 0;
    }

    .container {
        position: relative;

        img {
            width: 100vw;
        }
        .top-img {
            margin-top: 10px;
            position: inherit;
            display: flex;
        }
    }

    // .blank {
    //     position: inherit;
    //     display: flex;
    //     width: 100vw;
    //     height: 184px;
    // }

    .box-grad-privacy {
        width: 100vw;
        bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        // position: fixed;
        background-color: white;
        @include center;

        .btn-privacy {
            width: calc(100vw - (24px * 2));
            min-height: 44px;
            background: none;
            color: $blue-facebook;
            border: solid 1px $blue-facebook;
            font-size: 15px;

            border-radius: 8px;
            align-items: center;
            padding: 13px 0 10px 0;
            // font-family: NotoSansCJKkr-Medium !important;
            @include f-medium;
            letter-spacing: -0.2px;

            &.free-privacy {
                height: 44px;
            }
            &.buy-privacy {
                height: 60px;
                .price {
                    @include f-bold;
                    color: black;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
